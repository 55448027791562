import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { NavLink } from "react-router-dom";
import './partner-menu.scss';
import Fade from 'react-reveal/Fade';

class PartnerMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            network: '',
            height: 0,
            navFadeMode: false
        };
        this.myRef = React.createRef();
    }


    toggle = () => {
        const { height } = this.state;
        this.setState({
            height: height === 0 ? '100%' : 0,
        });
    };

    analyticsFunc = (e) => {
        this.setState({ navFadeMode: true })
    }


    render() {
        return (
            <div className="partner-menu">
                <Fade bottom onReveal={() => this.analyticsFunc(true)}>
                    <nav className="tab-menu bg-primary shadow-1">
                        <ul className="mb-0">
                            <li><NavLink onClick={() => this.props.goToSection({ 'downArrow': 1 })} activeClassName="active" to="/partner/brand">{this.props.data.tab_1.tab_name}</NavLink></li>
                            <li><NavLink onClick={() => this.props.goToSection({ 'downArrow': 1 })} activeClassName="active" to="/partner/start-up">{this.props.data.tab_2.tab_name}</NavLink></li>
                        </ul>
                    </nav>
                </Fade>
            </div>
        )
    }
}


const mapStateToProps = state => {
    //Manp the propps for local state
    return {
        header_close_action: state.auth.header_close_action,
        router: state.router,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        goToSection: (details) => dispatch(actions.changeHeaderCloseBtn(details)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PartnerMenu);