import React, { Component } from 'react';
import TitleComponent from '../../../../components/title/title';
import ReactPageScroller from "react-page-scroller";

// import page component
import PartnerMenu from '../../../../components/partner/partner-menu/partner-menu';
import PartnerLanding from '../../../../components/partner/partner-landing/partner-landing';
import PartnerType from '../../../../components/partner/partner-type/partner-type';
import WiseUp from '../../../../components/partner/wise-up/wise-up';
import PrimaryCarousel from '../../../../components/primary-carousel/primary-carousel';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';

class PartnerBrand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            blockScrollMode: true,
        };
    }

	/**
	 * section onchange get event and update state for current section noumber.
	 * add all scetion a coomon class and active class for current section.
	 */
    pageOnChange = (number) => {
        if (document.getElementsByClassName('activeSection').length > 0) {
            document.getElementsByClassName('activeSection')[0].classList.remove("activeSection");
        };
        if (this._pageScroller) document.getElementsByClassName('cmnPmH')[number - 1].parentNode.classList.add("activeSection", "cmnPmSec");
        setTimeout(() => {
            this.setState({ currentPage: number });
            this.props.updateStates(number);
        }, 600);
        if (number > 1) {
            document.getElementsByTagName('body')[0].classList.add('bg-primary');
            setTimeout(() => {
                this.props.landingScroll({ 'landingScroll': false });
            }, 800)
        }
        if (number === 1 && !this.state.blockScrollMode) {
            setTimeout(() => {
                this.setState({ blockScrollMode: true });
            }, 800);
        }
    };


	/**
	 * **gotToSection** handling to go section through the function.
	 */
    gotToSection(eventKey) {
        if (eventKey.closeBtn === 0) {
            if (this._pageScroller) {
                this._pageScroller.goToPage(eventKey.closeBtn);
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            this.props.goToSection({ 'test': false });
            this.props.landingScroll({ 'landingScroll': false });
            this.pageOnChange(1);
        }
        if (eventKey.downArrow) {
            if (this._pageScroller) this._pageScroller.goToPage(eventKey.downArrow);
            this.props.goToSection({ 'test': false });
        };
    }

    componentDidMount() {
        window.scroll(0, 0);
        if (document.getElementById('closeBtn')) document.getElementById('closeBtn').click();
        setTimeout(() => {
            document.getElementsByClassName('cmnPmH')[0].parentNode.parentNode.classList.add("parentSec");
            document.getElementsByClassName('cmnPmH')[0].parentNode.parentNode.parentNode.classList.add("stopScroll");
        })
    }

    componentWillUnmount() {
        document.getElementsByTagName('body')[0].classList.remove('bg-primary');
    }

    render() {
        const title = 'Partner Progam | Wiser Wash';
        const description = 'Creating a movement and calling on all brands and start-ups to save 1400 billion liters of clean water per year. Join the cause.';

        const sliderData = {
            "slides": [
                {
                    "title": "PROCESS",
                    "image": "http:\/\/demo721.indusnet.cloud\/MobSkipper\/WiserWash\/wp\/wp-content\/uploads\/2019\/09\/jeans.jpeg",
                    "description": "The Wiser Wash \u00ae process eliminates the use of pumice stones and toxic chemicals involved in traditional washing while still achieving beautiful abrasions and bright contrasts through decolorization with only one cup of water.",
                    "link_label": "DISCOVER OUR PROCESS",
                    "link": "#"
                },
                {
                    "title": "PARTNER PROGRAM",
                    "image": "http:\/\/demo721.indusnet.cloud\/MobSkipper\/WiserWash\/wp\/wp-content\/uploads\/2019\/09\/photo-1496902526517-c0f2cb8fdb6a.jpeg",
                    "description": "There\u2019s a strong call for sustainability. We\u2019re here to help you act wiser: we offer you concrete and doable solutions, that enable you to keep selling on a big scale, while becoming more sustainable. We\u2019ll make sustainability a blessing.\r\n",
                    "link_label": "Discover our partner program",
                    "link": "#"
                }
            ]
        }

        if (this.props.header_close_action !== undefined) {
            this.gotToSection(this.props.header_close_action);
        }

        if (this.props.landingScrollAction !== undefined && this.props.landingScrollAction.landingScroll && this.state.blockScrollMode) {
            setTimeout(() => {
                this.setState({ blockScrollMode: false });
            }, 800);
        }

        return (
            <div>
                <TitleComponent title={title} description={description} />
                <PartnerMenu></PartnerMenu>
                <ReactPageScroller transitionTimingFunction="ease-out" animationTimer={600} blockScrollDown={this.state.blockScrollMode} ref={c => this._pageScroller = c} pageOnChange={this.pageOnChange}>
                    <PartnerLanding width={this.props.width} currentPage={this.state.currentPage}></PartnerLanding>
                    <PartnerType></PartnerType>
                    <WiseUp></WiseUp>
                    <PrimaryCarousel data={sliderData}></PrimaryCarousel>
                </ReactPageScroller>
            </div>



        )
    }
}

const mapStateToProps = state => {
    //Manp the propps for local state
    return {
        header_close_action: state.uiProps.header_close_action,
        landingScrollAction: state.uiProps.landing_scroll_action,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        landingScroll: (details) => dispatch(actions.landingScrollEvent(details)),
        goToSection: (details) => dispatch(actions.changeHeaderCloseBtn(details)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerBrand);