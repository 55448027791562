import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './primary-carousel.scss';
import ReactSVG from 'react-svg';
import nextArrow from '../../assets/imgs/icons/next-arrow.svg';
import prevArrow from '../../assets/imgs/icons/prev-arrow.svg';
import Slider from "react-slick";
import Equalizer from 'react-equalizer';
import { Link } from "react-router-dom";

// SamplePrevArrow
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="prev-container">
            <Container className="position-relative">
                <div className={className} onClick={onClick}>
                    <ReactSVG src={prevArrow} style={{ width: 32 + 'px' }} />
                </div>
            </Container>
        </div>
    );
}

// SampleNextArrow
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <Container className="position-relative">
            <div
                className={className}
                onClick={onClick}
            ><ReactSVG src={nextArrow} style={{ width: 32 + 'px' }} /></div>
        </Container>
    );
}

class PrimaryCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            className: "center",
            centerMode: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        variableWidth: false,
                    }
                }
            ]
        };

        const slideImg = {
            slidesToShow: 1,
            arrows: false,
            fade: true,
            infinite: true,
        }
        const slideContent = {
            slidesToShow: 2,
            swipeToSlide: true,
            focusOnSelect: true,
            centerMode: true,
            infinite: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        }
        return (
            <section className="cmnPmH mob-height h-vh position-relative d-flex justify-content-center flex-column w-100 carousel-wrap bg-white">
                {/* slider for desktop version start */}
                <Slider {...settings} className="carousel-primary d-xl-block d-none">
                    {this.props.data.slides.map((slide, index) => {
                        return (
                            <Container key={'home-carousal-desktop-' + index} className="item position-relative">
                                <Fade fade>
                                    <img alt="" src={slide.image} className="right bottom z-index-1 position-absolute object-cover" width="50%" height="430" />
                                </Fade>
                                <Row noGutters="true">
                                    <Col md={12} className="bg-primary text-white text-left">
                                        <Fade bottom cascade>
                                            <Link to={'/' + slide.link} className="content custom-cursor animation-flicker-stop">
                                                <h2 className="d-block display-1 mb-4 text-white">{slide.title}</h2>
                                                <span className="d-block text-white">{slide.description}</span>
                                            </Link>
                                        </Fade>
                                    </Col>

                                    <Col md={12} className="text-left discover d-flex align-items-center">
                                        <Fade bottom>
                                            <Link to={'/' + slide.link} className="d-block h6 text-primary mb-0 text-uppercase">{slide.link_label}</Link>
                                        </Fade>
                                    </Col>
                                </Row>
                            </Container>
                        )
                    })}
                </Slider>
                {/* slider for desktop version end */}

                <Container fluid="true" className="d-block d-xl-none p-0 m-0">
                    <div className="bg-slider">
                        <Slider
                            asNavFor={this.state.nav2}
                            ref={slider => (this.slider1 = slider)}
                            {...slideImg}>
                            {this.props.data.slides.map((slide, index) => {
                                return <img alt="" key={'home-carousal-mobile-image-' + index} src={slide.image} className="object-cover" width="100%" height="100%" />
                            })}
                        </Slider>
                    </div>

                    <div className="slideContent">
                        <Slider
                            asNavFor={this.state.nav1}
                            ref={slider => (this.slider2 = slider)}
                            {...slideContent} >

                            {this.props.data.slides.map((slide, index) => {
                                return (
                                    <Link to={'/' + slide.link} key={'home-carousal-mobile-' + index} className="content h-100 bg-primary text-white p-3 d-block">
                                        <Equalizer>
                                            <Fade>
                                                <h2 className="d-block display-1 text-ellipsis mb-4 animation-flicker-stop text-white">{slide.title}</h2>
                                                <span className="d-block animation-flicker-stop">{slide.description}</span>
                                            </Fade>
                                        </Equalizer>
                                    </Link>
                                )
                            })}
                        </Slider>
                    </div>
                </Container>

            </section>
        )
    }
}

export default PrimaryCarousel;