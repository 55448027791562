import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';



class ProcessWaterCup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cauntA: true,
            cauntB: true,
            cauntC: true,
        }
    }

    onChangeA = (e) => {
        if (e && this.state.cauntA) {
            this.myCountUpA.restart();
            this.setState({ cauntA: false });
        }
    }
    onChangeB = (e) => {
        if (e && this.state.cauntB) {
            this.myCountUpB.restart();
            this.setState({ cauntB: false });
        }
    }
    onChangeC = (e) => {
        if (e && this.state.cauntC) {
            this.myCountUpC.restart();
            this.setState({ cauntC: false });
        }
    }



    render() {
        return (
            <section className="bg-primary cmnPmH h-vh position-relative text-white d-flex align-items-center mob-height">
                <Container>
                    <Row>
                        <Col md={6} lg={4} className="mb-4 mb-md-5">
                            <Fade>
                                <span className="d-block display-md text-center mb-2 mb-md-3 mb-lg-5">
                                    <VisibilitySensor onChange={this.onChangeA}>
                                        <CountUp
                                            start={100}
                                            end={parseInt(this.props.data.pumice_stones.value)}
                                            duration={5}
                                            separator=" "
                                            decimals={0}
                                            decimal="."
                                            suffix={this.props.data.pumice_stones.suffix}
                                            ref={countUp => { this.myCountUpA = countUp; }}
                                        >
                                        </CountUp>
                                    </VisibilitySensor>
                                </span>
                                <span className="d-block text-center font-size-lg">{this.props.data.pumice_stones.title}</span>
                            </Fade>
                        </Col>
                        <Col md={6} lg={4} className="mb-4 mb-md-5">
                            <Fade>
                                <span className="d-block display-md text-center mb-2 mb-md-3 mb-lg-5">
                                    <VisibilitySensor onChange={this.onChangeB}>
                                        <CountUp
                                            start={100}
                                            end={parseInt(this.props.data.hazardous_and_taxic_chemicals.value)}
                                            duration={5}
                                            separator=" "
                                            decimals={0}
                                            decimal="."
                                            suffix={this.props.data.hazardous_and_taxic_chemicals.suffix}
                                            ref={countUp => { this.myCountUpB = countUp; }}
                                        >
                                        </CountUp>
                                    </VisibilitySensor>

                                </span>
                                <span className="d-block text-center font-size-lg">{this.props.data.hazardous_and_taxic_chemicals.title}</span>
                            </Fade>
                        </Col>
                        <Col lg={4} className="mb-5">
                            <Fade>
                                <span className="d-block display-md text-center mb-2 mb-md-3 mb-lg-5">
                                    {parseInt(this.props.data.of_water_for_full_decolorization.value)}{this.props.data.of_water_for_full_decolorization.suffix}
                                    {/* <VisibilitySensor onChange={this.onChangeC}>
                                        <CountUp
                                            start={5}
                                            end={parseInt(this.props.data.of_water_for_full_decolorization.value)}
                                            duration={5}
                                            separator=" "
                                            decimals={0}
                                            decimal="."
                                            suffix={this.props.data.of_water_for_full_decolorization.suffix}
                                            ref={countUp => { this.myCountUpC = countUp; }}
                                        >
                                        </CountUp>
                                    </VisibilitySensor> */}
                                </span>
                                <span className="d-block text-center font-size-lg">{this.props.data.of_water_for_full_decolorization.title}</span>
                            </Fade>
                        </Col>
                        <Col md={{ span: 8, offset: 2 }}>
                            <Fade>
                                <span className="firstReavel d-block text-center">{this.props.data.description}</span>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section >
        )
    }
}



export default ProcessWaterCup;