import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';

import './planet.scss';

class WiserWashPlanet extends Component {

    render() {
        return (
            <section className="bg-primary mob-height cmnPmH h-vh position-relative text-white d-flex align-items-center planet">
                {/* for desktop and tablet version start */}
                <Fade>
                    {/* <img alt="" src={waves} width="50%" height="100%" className="object-cover position-absolute cover-img" /> */}
                    <img alt="" src={this.props.data.media.images[0]} width="50%" height="100%" className="object-cover position-absolute cover-img" />
                </Fade>
                <Container className="cotent">
                    <Row>
                        <Col md={{ span: 6, offset: 6 }} xs={8} className="d-flex justify-content-end">
                            <Fade bottom cascade>
                                <figure className="d-block content-rw-100 mb-0">
                                    <span className="d-block display-1 mb-lg-5 mb-md-3">{this.props.data.title}</span>
                                    <span className="d-md-block d-none">{this.props.data.description}</span>
                                </figure>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
                {/* for desktop and tablet version end */}

                {/* for mobile version start */}
                <Container className="d-md-none d-block pt-5">
                    <Fade bottom>
                        <span className="d-block">{this.props.data.description}</span>
                    </Fade>
                </Container>
                {/* for mobile version end */}
            </section>
        )
    }
}



export default WiserWashPlanet;