import React, { Component } from 'react';
import axios from 'axios';

class ContactForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {
                name: '',
                email: '',
                message: ''
            },
            errors: {},
            loading: false
        }
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Cannot be empty";
        }

        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }

        if (typeof fields["email"] !== "undefined") {
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regex.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }

        //Message
        if (!fields["message"]) {
            formIsValid = false;
            errors["message"] = "Cannot be empty";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    contactSubmit(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.setState({ loading: true });
            axios.post('/contactus', {
                formdata: "ALL",
                person_name: this.state.fields.name,
                person_email: this.state.fields.email,
                person_message: this.state.fields.message
            }).then(response => {
                if (response.data.status) {
                    this.setState({ fields: { name: '', email: '', message: '' } });
                    this.props.onSuccess(response.data.message);
                    this.setState({ loading: false });
                }
            })
        } else {
            // alert("Form has errors.")
        }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value; 
        this.setState({ fields });
        this.handleValidation();
    }


    render() {
        return (
            <form className="form-default" name="contactform" onSubmit={this.contactSubmit.bind(this)}>
                <div className="form-group mb-4">
                    <input className="border-style" type="text" placeholder={this.props.data.name_placeholder} onChange={this.handleChange.bind(this, "name")} value={this.state.fields.name}></input>
                    {this.state.errors["name"] ? <span className='error  text-danger small'>{this.state.errors["name"]}</span> : null}
                </div>
                <div className="form-group mb-4">
                    <input className="border-style" type="text" placeholder={this.props.data.email_placeholder} onChange={this.handleChange.bind(this, "email")} value={this.state.fields.email}></input>
                    {this.state.errors["email"] ? <span className='error  text-danger small'>{this.state.errors["email"]}</span> : null}
                </div>
                <div className="form-group mb-4">
                    <textarea className="border-style" placeholder={this.props.data.message_placeholder} onChange={this.handleChange.bind(this, "message")} value={this.state.fields.message}></textarea>
                    {this.state.errors["message"] ? <span className='error  text-danger small'>{this.state.errors["message"]}</span> : null}
                </div>
                <button className="form-submit h4" style={{ fontWeight: "400" }} type="submit">{this.props.data.button_label}</button>

                {this.state.loading ? <span>Loading...</span> : null}
            </form>
        )
    }
}

export default ContactForm;