import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.scss';
// import AsyncComponent from './hoc/asyncComponent/asyncComponent';

import Index from './containers/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPageScroller from "react-page-scroller";

// import providers
import localstorage from "local-storage";
import { ParallaxProvider } from 'react-scroll-parallax';

// import pages
import WiserWash from './containers/pages/wiser-wash/wisher-wash';
import Process from './containers/pages/process/process';
import Contact from './containers/pages/contact/contact';
import Partner from './containers/pages/partner/partner';
import PartnerBrand from './containers/pages/partner/brand/brand';
import PartnerStartUp from './containers/pages/partner/start-up/start-up';
import MainHeader from './components/header/header';
// import { TabMenu } from "./components/tab-menu/tab-menu";
import NotFound from "./containers/notFound/notFound";
import * as actions from './store/actions/index';


class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currentPage: 1,
			width: props.width
		};
	}

	componentDidMount() {
		this.getDetactBrowserAndDevice();
	}
	getDetactBrowserAndDevice() {
		const _targetBody = document.getElementsByTagName('body')[0];
		const isChrome = (navigator.userAgent.indexOf('Chrome') > 0)
		const isMacOs = (navigator.userAgent.indexOf('Mac') > 0);
		const isAndroidOs = (navigator.userAgent.indexOf('Android') > 0);

		if (isChrome) _targetBody.classList.add('chrome');
		if (isMacOs) _targetBody.classList.add('mac-os');
		if (isAndroidOs) _targetBody.classList.add('android-os');

	}

	/**
	 * 
	 * **updateState**get the currentpage status from wisherwash component
	 * and pass the data mainheader component.
	 */
	updateState($event) {
		this.setState({ currentPage: $event });
	}

	componentWillMount() {
		this.setState({ width: window.innerWidth });
	}


	render() {
		// var userType = localstorage.get('user_type');
		if ('isError' in this.props && this.props.isError == true && 'error' in this.props && this.props.error) {
			toast.error(this.props.error.message, {
				position: toast.POSITION.TOP_RIGHT
			});
		}


		function PrivateRoute({ component: Component, ...rest }) {
			let token = 1 //localstorage.get('token');

			return (

				<Route
					{...rest}
					render={props =>
						token ? (
							<Component {...props} />
						) : (
								<Redirect
									to={{
										pathname: "/",
										state: { from: props.location }
									}}
								/>
							)
					}
				/>
			);
		}

		let routes = (
			<Switch>
				<Route
					path="/wiser-wash"
					render={(props) => <WiserWash {...props} width={this.state.width} updateStates={this.updateState.bind(this)} />}
				/>
				<Route
					path="/process"
					render={(props) => <Process {...props} width={this.state.width} updateStates={this.updateState.bind(this)} />}
				/>
				<Route
					path="/partner/:tab"
					render={(props) => <Partner {...props} width={this.state.width} updateStates={this.updateState.bind(this)} />}
				/>
				<Route
					path="/contact"
					render={(props) => <Contact {...props} />}
				/>
				{/* <Redirect exact from="/partner" push to="/partner/brand" /> */}
				<Redirect exact from="/" push to="/wiser-wash" />
				<Route path="*" component={NotFound} />

			</Switch>
		);


		return (
			<div>
				{/* header start */}
				<MainHeader headerProps={this.state.currentPage}></MainHeader>
				{/* header end */}

				{routes}
				<ToastContainer />
			</div>
		);
	}
}

const mapStateToProps = state => {

	return {
		error: state.auth.error,
		isError: state.auth.isError
	};
}


export default withRouter(connect(mapStateToProps, null)(App));

// export default withRouter(App);