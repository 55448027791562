import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './magic.scss';

class WiserWashMagic extends Component {

    render() {
        return (
            <section className="bg-primary mob-height cmnPmH h-vh position-relative text-white d-flex align-items-center magic-wrap">
                {/* for desktop and tab start */}
                <Fade>
                    <img alt="" src={this.props.data.media.images[0]} width="64%" height="82%" className="object-cover position-absolute right bottom magic img d-md-block d-none" />
                </Fade>
                <Container className="d-md-block d-none animation-flicker-stop">
                    <Row>
                        <Col md={6} className="d-flex justify-content-end">
                            <Fade bottom cascade>
                                <figure className="mt-lg-5 mb-0">
                                    <span className="d-block display-1 mb-4 mb-md-5 title animation-flicker-stop">{this.props.data.title}</span>
                                    <span className="d-block sw-75 des animation-flicker-stop">{this.props.data.description}</span>
                                </figure>
                            </Fade>
                        </Col>

                    </Row>
                </Container>
                {/* for desktop and tab end */}

                {/* for mobile start */}
                <Container className="d-md-none d-block px-0">
                    <Row>
                        <Col xs={{ size: 8, offset: 5 }}>
                            <Fade>
                                <img alt="" src={this.props.data.media.images[0]} width="100%" height="100%" className="object-cover img" />
                            </Fade>
                        </Col>
                        <Col sm={9} xs={12}>
                            <Fade bottom cascade>
                                <figure className="mt-lg-5 mb-0 px-3 content">
                                    <span className="d-block display-1 mb-3 title">{this.props.data.title}</span>
                                    <span className="d-block des">{this.props.data.description}</span>
                                </figure>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
                {/* for mobile end */}
            </section>
        )
    }
}



export default WiserWashMagic;