import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import thunk from 'redux-thunk';
import "../node_modules/slick-carousel/slick/slick.scss";
import "../node_modules/slick-carousel/slick/slick-theme.scss";
import './index.scss';
import App from './app';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import AuthReducer from './store/reducers/auth';
import UiPropsReducer from './store/reducers/reducers.uiProps';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common['Authorization'] = process.env.REACT_APP_API_KEY;
axios.interceptors.response.use(function (response) {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response;
}, function (error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	return Promise.reject(error);
});

const rootReducer = combineReducers({
	auth: AuthReducer,
	uiProps: UiPropsReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

const app = (
	<Provider store={store}>
		<BrowserRouter basename={'/' + process.env.REACT_APP_SUB_DIR}>
			<App />
		</BrowserRouter>
	</Provider>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
	ReactDOM.hydrate(app, rootElement);
} else {
	ReactDOM.render(app, rootElement);
}