import React, { Component } from 'react';
import ReactSVG from 'react-svg';
import { Container, Row, Col } from 'react-bootstrap';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import AnimateHeight from 'react-animate-height';
import { Link, NavLink } from "react-router-dom";
import './tab-menu.scss';
import Fade from 'react-reveal/Fade';
import { withRouter } from "react-router-dom";
import { compose } from 'redux';

class TabMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            network: '',
            height: 0,
            navFadeMode: false
        };
        this.myRef = React.createRef();
    }


    toggle = () => {
        const { height } = this.state;
        this.setState({
            height: height === 0 ? '100%' : 0,
        });
    };

    analyticsFunc = (e) => {
        this.setState({ navFadeMode: true })
    }


    render() {
        return (
            <Fade bottom onReveal={() => this.analyticsFunc(true)}>
                <nav className={'tab-menu bg-white shadow-1' + (this.state.navFadeMode ? ' fadeDone' : '')}>
                    <ul className="mb-0">
                        <li><NavLink activeClassName="active" to="/wiser-wash">Wiser Wash</NavLink></li>
                        <li><NavLink activeClassName="active" to="/process">Process</NavLink></li>
                        <li><NavLink activeClassName="active" to={this.props.match.params.tab ? this.props.match.url : '/partner/brand'}>Partner Program</NavLink></li>
                    </ul>
                </nav>
            </Fade>
        )
    }
}
 

const mapStateToProps = state => {
    //Manp the propps for local state
    return {
        header_close_action: state.auth.header_close_action,
        router: state.router,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        goToSection: (details) => dispatch(actions.changeHeaderCloseBtn(details)),
    }
}
export default compose(withRouter,connect(mapStateToProps, mapDispatchToProps))(TabMenu);