import React, { Component } from 'react';
import axios from 'axios';
import ReactPageScroller from "react-page-scroller";
import TitleComponent from '../../../components/title/title';

// import page component
import WiserWashLanding from '../../../components/wiser-wash/landing/landing';
import WiserWashBlessing from '../../../components/wiser-wash/blessing/blessing';
import WiserWashPlanet from '../../../components/wiser-wash/planet/planet';
import WiserWashAim from '../../../components/wiser-wash/aim/aim';
import WiserWashIndigo from '../../../components/wiser-wash/indigo/indigo';
import WiserWashMagic from '../../../components/wiser-wash/magic/magic';
import WiserWashEarth from '../../../components/wiser-wash/earth/earth';
import { connect } from 'react-redux';
import WiserWashWiseUp from '../../../components/wiser-wash/wise-up/wise-up';
import PrimaryCarousel from '../../../components/primary-carousel/primary-carousel';
import * as actions from '../../../store/actions/index';

class WiserWash extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: 1,
			blockScrollMode: true,
			pageData: null
		};
	}

	/**
	 * section onchange get event and update state for current section noumber.
	 * add all scetion a coomon class and active class for current section.
	 */
	pageOnChange = (number) => {
		if (document.getElementsByClassName('activeSection').length > 0) {
			document.getElementsByClassName('activeSection')[0].classList.remove("activeSection");
		};
		if (this._pageScroller) document.getElementsByClassName('cmnPmH')[number - 1].parentNode.classList.add("activeSection", "cmnPmSec");
		setTimeout(() => {
			this.setState({ currentPage: number });
			this.props.updateStates(number);
		}, 600);
		if (number > 1) {
			document.getElementsByTagName('body')[0].classList.add('bg-primary');
			setTimeout(() => {
				this.props.landingScroll({ 'landingScroll': false });
			}, 800);
		}
		if (number === 1 && !this.state.blockScrollMode) {
			setTimeout(() => {
				this.setState({ blockScrollMode: true });
				document.getElementsByTagName('body')[0].classList.remove('bg-primary');
			}, 800);
		}
		if (number == 2) {
			setTimeout(() => { this.checkReactReaval(); }, 800);
		}
	};


	/**
	 * **gotToSection** handling to go section through the function.
	 */
	gotToSection(eventKey) {
		if (eventKey.closeBtn === 0) {
			if (this._pageScroller) {
				// this._pageScroller.goToPage(eventKey.closeBtn);
				setTimeout(() => {
					this._pageScroller.goToPage(eventKey.closeBtn)
				}, 700);
			} else {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			}
			this.props.goToSection({ 'test': false });
			this.props.landingScroll({ 'landingScroll': false });
			this.pageOnChange(1);
		}
		if (eventKey.downArrow) {
			if (this._pageScroller) this._pageScroller.goToPage(eventKey.downArrow);
			this.props.goToSection({ 'test': false });
		};
	}

	componentDidMount() {
		if (document.getElementById('closeBtn')) document.getElementById('closeBtn').click();
		axios.post('/wiser_wash', { section: 'ALL' }).then(response => {
			this.setState({ pageData: response.data.page });
		})
	}

	componentWillUnmount() {
		document.getElementsByTagName('body')[0].classList.remove('bg-primary');
	}

	/**
	 * this function added for mac safari browser bellow 12.1 react reveal not work.
	 * we added a class in body by this class we set custtom react reveal fadein and fadeinUp aniumation for animated element.
	 * we call this function by checking if react real class opacity is 0 then add the class. 
	 * call the function in pageonchange function when section scroll currenty section is 2.
	 * and add a **firstReavel** class in scetion 2 for one react reveal animated element.
	 */
	checkReactReaval() {
		const _ReavelOpacity = document.getElementsByClassName('firstReavel')[0].style.opacity;
		if (_ReavelOpacity == 0 || !_ReavelOpacity) {
			document.getElementsByTagName('body')[0].classList.add('forceReavel');
		}
	}

	render() {

		const title = 'Wiser Wash | The true beauty of indigo, made better';
		const description = 'Wiser is the initiative to ignite a whole new breed of fashion: wiser fashion. It’s sustainable fashion, without compromising on looks, quality or price and margins. Take a look.';

		if (this.props.header_close_action !== undefined) {
			this.gotToSection(this.props.header_close_action);
		}

		if (this.props.landingScrollAction !== undefined && this.props.landingScrollAction.landingScroll && this.state.blockScrollMode) {
			setTimeout(() => {
				this.setState({ blockScrollMode: false });
			}, 1200);
		}

		let mainContent = null;

		if (this.state.pageData !== null) {

			mainContent = this.props.width > 1024 ? (
				<ReactPageScroller transitionTimingFunction="ease-out" animationTimer={600} blockScrollDown={this.state.blockScrollMode} ref={c => this._pageScroller = c} pageOnChange={this.pageOnChange}>
					<WiserWashLanding width={this.props.width} data={this.state.pageData.banner_section} currentPage={this.state.currentPage}></WiserWashLanding>
					<WiserWashBlessing data={this.state.pageData.section_1}></WiserWashBlessing>
					<WiserWashPlanet data={this.state.pageData.section_2}></WiserWashPlanet>
					<WiserWashAim data={this.state.pageData.section_3}></WiserWashAim>
					<WiserWashIndigo data={this.state.pageData.section_4}></WiserWashIndigo>
					<WiserWashMagic data={this.state.pageData.section_5}></WiserWashMagic>
					<WiserWashEarth data={this.state.pageData.section_6}></WiserWashEarth>
					<WiserWashWiseUp data={this.state.pageData.section_7}></WiserWashWiseUp>
					<PrimaryCarousel data={this.state.pageData.section_8}></PrimaryCarousel>
				</ReactPageScroller>
			) : (
					<section className="wrapContent">
						<WiserWashLanding width={this.props.width} data={this.state.pageData.banner_section} currentPage={this.state.currentPage}></WiserWashLanding>
						<WiserWashBlessing data={this.state.pageData.section_1}></WiserWashBlessing>
						<WiserWashPlanet data={this.state.pageData.section_2}></WiserWashPlanet>
						<WiserWashAim data={this.state.pageData.section_3}></WiserWashAim>
						<WiserWashIndigo data={this.state.pageData.section_4}></WiserWashIndigo>
						<WiserWashMagic data={this.state.pageData.section_5}></WiserWashMagic>
						<WiserWashEarth data={this.state.pageData.section_6}></WiserWashEarth>
						<WiserWashWiseUp data={this.state.pageData.section_7}></WiserWashWiseUp>
						<PrimaryCarousel data={this.state.pageData.section_8}></PrimaryCarousel>
					</section>

				)
		}

		return (
			<React.Fragment>
				<TitleComponent title={title} description={description} />
				{mainContent}
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	//Manp the propps for local state
	return {
		header_close_action: state.uiProps.header_close_action,
		landingScrollAction: state.uiProps.landing_scroll_action,
	}
}


const mapDispatchToProps = dispatch => {
	return {
		landingScroll: (details) => dispatch(actions.landingScrollEvent(details)),
		goToSection: (details) => dispatch(actions.changeHeaderCloseBtn(details)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WiserWash);