import React, { Component } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link, NavLink } from "react-router-dom";
import ReactSVG from 'react-svg';
import play from '../../../assets/imgs/icons/play.svg';
import './wise-up.scss';
import crossArrow from '../../../assets/imgs/icons/cross.svg';

class ProcessWiseUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalShow: false
        };
    }

    setModalShow = (param) => {
        this.setState({ modalShow: param });
    }


    render() {
        return (
            <section className="bg-primary mob-height cmnPmH h-vh position-relative text-white wp-wise-up">
                {/* for desktop version start */}
                <Container className="h-100 d-md-block d-none">
                    <Row className="h-100">
                        <Col md={6} className="d-flex align-items-center">
                            <Fade bottom cascade>
                                <figure className="mb-0">
                                    <span className="d-block display-1 mb-5 title">{this.props.data.tile}</span>
                                    <span className="d-block sw-50 des">{this.props.data.description}</span>
                                </figure>
                            </Fade>
                        </Col>
                        <Col md={6} className="d-flex align-items-center">
                            <Fade bottom>
                                <img alt="" src={this.props.data.media.images[0]} className="object-cover summerTime" width="100%" />
                            </Fade>
                            <Fade bottom>
                                <div className="video-holder">
                                    <Link to="/process" onClick={() => this.setModalShow(true)} className="z-index-1 wwJeans">
                                        <span className="play">
                                            <ReactSVG src={play} style={{ width: 18 + 'px' }} />
                                        </span>
                                        <img alt="" src={this.props.data.media.images[1]} className="object-contain" />
                                    </Link>
                                </div>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
                {/* for desktop version end */}

                <Container className="d-md-none d-block">
                    <Row>
                        <Col sm={{ span: 7, offset: 5 }} xs={{ span: 8, offset: 4 }}>
                            <Fade bottom>
                                <img alt="" src={this.props.data.media.images[0]} className="object-cover cover-img summerTime" width="100%" />
                            </Fade>
                        </Col>
                        <Col xs={7}>
                            <Fade bottom>
                                <Link to="/process" onClick={() => this.setModalShow(true)} className="absolute-img position-relative d-block">
                                    <span className="play">
                                        <ReactSVG src={play} style={{ width: 18 + 'px' }} />
                                    </span>
                                    <img alt="" src={this.props.data.media.images[1]} className="absolute-img" width="100%" />
                                </Link>
                            </Fade>
                        </Col>
                        <Col xs={5}>
                            <Fade bottom>
                                <span className="d-block display-1 title">WISE UP. <br />WASH WISER.</span>
                            </Fade>
                        </Col>
                        <Col xs={12} className="mt-5">
                            <Fade bottom>
                                <span className="d-block des">{this.props.data.description}</span>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

                {/* video modal */}
                <VideoModal
                    videolink={this.props.data.media.vidoes}
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                />
            </section>
        )
    }
}

function VideoModal(props) {
    return (
        <Modal {...props} size="lg" centered>
            <Button onClick={props.onHide} className="modal-close">
                <ReactSVG src={crossArrow} style={{ width: 12 + 'px' }} />
            </Button>
            <Modal.Body>
                <iframe id="ytplayer" type="text/html" width="100%" height="405"
                    src={props.videolink + '?autoplay=1&disablekb=1'}
                    frameBorder="0" allowFullScreen></iframe>
            </Modal.Body>
        </Modal>
    );
}

export default ProcessWiseUp;