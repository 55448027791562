import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import ReactSVG from 'react-svg';
import RightArrow from '../../assets/imgs/icons/next-arrow.svg';
import Fade from 'react-reveal/Fade';
import './notFound.scss';
import TitleComponent from '../../components/title/title';

const NotFound = () => (
    <React.Fragment>
        <TitleComponent title={'404'} description={''} />
        <div className="bg-white">
            <Container className="d-flex flex-column align-items-center justify-content-center vh-100 not-found text-center">
                <h1 className="display-md text-primary mb-3">404</h1>
                <span className="d-block font-family-heading display-4 mb-3 text-primary">Looks like your'e lost</span>
                <span className="d-block">The page you are looking for is not available right now</span>
                <Fade>
                    <Link to="/wiser-wash" className="d-flex align-items-center h4 mt-4 mt-lg-5 mb-0 text-uppercase">
                        Back to home
                <ReactSVG className="ml-3" src={RightArrow} style={{ width: 28 + 'px' }} />
                    </Link>
                </Fade>
            </Container>
        </div>
    </React.Fragment>

);
export default NotFound;