import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './blesssing.scss';

class WiserWashBlessing extends Component {

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section className="blessing bg-primary mob-height cmnPmH h-vh position-relative text-white d-flex align-items-center">
                {/* <span className="line wwblessing-line"></span> */}
                <Container>
                    <Row>
                        <Col md={12}>
                            <Fade bottom>
                                <span className="firstReavel d-block display-1" dangerouslySetInnerHTML={this.createMarkup(this.props.data.title)}></span>
                            </Fade>
                        </Col>
                        <Col md={6} className="mt-3">
                            <Fade bottom>
                                <span className="d-block h1 font-family-body text-white content-lw-100 mb-0">{this.props.data.description}</span>
                            </Fade>
                        </Col>
                        <Col md={6} className="d-flex justify-content-end mt-md-3 mt-5">
                            <Fade bottom>
                                <span className="d-block content-rw-100">{this.props.data.sub_description}</span>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}



export default WiserWashBlessing;