import React, { Component } from 'react';
import axios from 'axios';
import ReactSVG from 'react-svg';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../../assets/imgs/logo.svg';

import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../store/actions/index';
import AnimateHeight from 'react-animate-height';
import { Link, NavLink, withRouter } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import './header.scss';


class MainHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            network: '',
            height: 0,
            socialData: null,
            brandData: null,
            menuData: null
        };
        this._pageScroller = null;
        this.myRef = React.createRef();
    }

    onSelect = (val) => {
        document.getElementsByTagName('body')[0].classList.remove('navOpen');
        if (this.state.height !== 0) { this.setState({ height: 0 }) }
        else if (document.getElementById('closeBtn')) document.getElementById('closeBtn').click();
    }


    toggle = () => {
        const { height } = this.state;
        this.setState({
            height: height === 0 ? '100%' : 0,
        });
        if (this.state.height === 0) {
            document.getElementsByTagName('body')[0].classList.add('navOpen');
        } else {
            document.getElementsByTagName('body')[0].classList.remove('navOpen');
        }
    };

    componentDidMount() {
        axios.get('/social_link').then(response => {
            this.setState({ socialData: response.data });
        })

        axios.get('/general').then(response => {
            this.setState({ brandData: response.data });
        })

        axios.get('/on_page_menu').then(response => {
            this.setState({ menuData: response.data.menus });
        })
    }

    render() {
        let socialSection = null;
        let navSection = null;
        let menuSection = null;

        if (this.state.socialData !== null) {
            socialSection = this.state.socialData.map((data, index) => {
                return (
                    <li className="mr-3 mr-md-5" key={'header-social-desktop-' + index}>
                        <a href={data.social_media_link} target="_blank">
                            <img alt="" src={data.social_media_icon} />
                        </a>
                        {/* <Link to={data.social_media_link}>
                            <ReactSVG src={faceBook} style={{ width: 28 + 'px' }} />
                        </Link> */}
                    </li>
                )
            });
        }

        if (this.state.menuData !== null) {
            const navMenus = [...this.state.menuData];
            navMenus.splice(navMenus.length - 1, 1);


            menuSection = this.state.menuData.map((menu, index) => {
                const className = (index === this.state.menuData.length - 1) ? "display-3 mt-5" : "display-3";
                return (
                    <li className={className} key={'menu-list-' + menu.menu_id}>
                        <Link className="custom-cursor" to={'/' + menu.menu_link} onClick={() => this.onSelect('/' + menu.menu_link)}>{menu.menu_title}</Link>
                    </li>
                )
            })

            navSection = (
                <Row>
                    <Col className="logo">
                        <Link to="/wiser-wash" onClick={() => this.onSelect('/wiser-wash')} className="d-block">
                            <ReactSVG src={logo} style={{
                                width: 44 + 'px', 'fill':
                                    (this.props.headerProps > 1 ||
                                        this.state.height !== 0 ||
                                        this.props.landingScrollAction !== undefined &&
                                        this.props.landingScrollAction.landingScroll)
                                        &&
                                        (
                                            (this.props.location.pathname === '/wiser-wash' && this.props.headerProps !== 9) ||
                                            (this.props.location.pathname === '/process' && this.props.headerProps !== 10) ||
                                            (this.props.location.pathname === '/partner/brand' && this.props.headerProps !== 4) ||
                                            (this.props.location.pathname === '/partner/start-up' && this.props.headerProps !== 4)
                                        )
                                        ? '#fff' : '#0036ff'
                            }} />
                        </Link>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <div className="menuWrap">
                            <nav className={'menu d-none d-md-block ' + (this.state.height !== 0 ? 'invisible' : '')}>
                                <ul className="nav justify-content-end">
                                    {navMenus.map((menu) => {
                                        return (
                                            <li className="nav-item border-right" key={'nav-menu-' + menu.menu_id}>
                                                <Link to={'/' + menu.menu_link} className="nav-link active text-primary  py-0 px-4">{menu.menu_title}</Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </nav>
                        </div>
                        {/* menu */}
                        {
                            (this.props.headerProps > 1 ||
                                this.props.landingScrollAction !== undefined &&
                                this.props.landingScrollAction.landingScroll)
                                ?
                                (
                                    <a id="closeBtn" onClick={() => this.props.goToSection({ 'closeBtn': 0 })} className="d-block ml-4">
                                        {/* <ReactSVG src={crossArrow} style={{ width: 26 + 'px', 'fill': this.props.headerProps !== 10 ? '#fff' : '#0036ff' }} /> */}
                                        <div className={'nav-icon3 ' +
                                            (
                                                (this.props.headerProps !== 9 && this.props.location.pathname === '/wiser-wash') ||
                                                    (this.props.headerProps !== 10 && this.props.location.pathname === '/process') ||
                                                    (this.props.headerProps !== 4 && this.props.location.pathname === '/partner/brand') ||
                                                    (this.props.headerProps !== 4 && this.props.location.pathname === '/partner/start-up') 
                                                    ? 'open' : 'open primary')}>
                                            <span></span><span></span>
                                            <span></span><span></span>
                                        </div>
                                    </a>
                                ) : (
                                    <a id="closeNav" onClick={this.toggle} className="ml-4">
                                        <div className={'nav-icon3 ' + (this.state.height !== 0 ? 'open' : '')}>
                                            <span></span><span></span>
                                            <span></span><span></span>
                                        </div>
                                    </a>
                                )
                        }
                    </Col>
                </Row>
            );
        }

        return (
            <div>
                <Container fluid="true" className={'headerWrap py-xl-3 px-0 px-xl-3 position-fixed z-index-4' + (this.state.height !== 0 ? ' openNav' : ' noNav')}>
                    <header className="main-header px-3 py-3">
                        {navSection}
                    </header>
                </Container>

                {/* menu start */}
                <AnimateHeight duration={400} height={this.state.height ? this.state.height : 0} className="main-menu bg-primary">
                    <div className="d-flex flex-column vh-100">
                        <Container className="flex-1 d-flex align-items-center justify-content-md-start justify-content-center">
                            <Fade bottom cascade>
                                <ul className="list-mainmenu mb-0 px-3 px-xl-0 text-center text-md-left">
                                    {menuSection}
                                </ul>
                            </Fade>
                        </Container>
                        <footer className="menu-footer px-3 flex-none">
                            <Container fluid="true">
                                <Fade bottom>
                                    <Row>
                                        <Col md={6}>
                                            <ul className="list-social mb-3 mb-md-0 justify-content-center justify-content-md-start">
                                                <li className="mr-3 mr-md-5">Social</li>
                                                {socialSection}
                                            </ul>
                                        </Col>
                                        {this.state.brandData !== null ? (
                                            <Col md={6} className="text-center text-md-right">
                                                <span className="d-block text-white">{this.state.brandData.brand_sub_title}</span>
                                            </Col>
                                        ) : null}
                                    </Row>
                                </Fade>
                            </Container>
                        </footer>
                    </div>
                </AnimateHeight>
                {/* menu end */}
            </div>
        )
    }
}


const mapStateToProps = state => {
    //Manp the propps for local state
    return {
        header_close_action: state.uiProps.header_close_action,
        landingScrollAction: state.uiProps.landing_scroll_action,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        goToSection: (details) => dispatch(actions.changeHeaderCloseBtn(details)),
    }
}
// export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MainHeader);