import * as actionTypes from './actionType';

export const changeHeaderCloseBtn = (action) =>{
	return {
		type: actionTypes.HEADER_CLOSE_ACTION,
		details: action
	}
}


export const landingScrollEvent = (action) =>{
	return {
		type: actionTypes.LANDING_SCROLL_ACTION,
		details: action
	}
}
