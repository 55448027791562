import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import Fade from 'react-reveal/Fade';
import './benefit.scss';

class ProcessBenefit extends Component {
    render() {
        const settings = {
            dots: true,
            arrows: false,
            infinite: false,
            speed: 500,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true
            // vertical: true,
        };

        return (
            <section className="bg-primary text-white h-vh cmnPmH position-relative mob-height pt-0 wp-benefit">
                <Slider {...settings} className="h-100 verticalSlider">
                    {this.props.data.map((data, index) => {
                        return (
                            <div className="h-100 d-flex flex-column align-items-center justify-content-center" key={"benefit-desktop-" + index}>
                                <Fade>
                                    <img alt="" src={data.section_6_image} width="50%" height="100%" className="object-cover position-absolute right wp-v-f-img animation-flicker-stop" />
                                </Fade>
                                <Container>
                                    <Row className="h-100">
                                        <Col sm={8} md={9} className="mt-4 mb-5 animation-flicker-stop">
                                            <Fade bottom>
                                                <span className="d-block display-1 mt-0 mt-md-5 mb-lg-5 animation-flicker-stop">{data.section_6_title}</span>
                                            </Fade>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container fluid="true" className="mt-5 animation-flicker-stop">
                                    <Row>
                                        <Col sm={6} className="text-left text-sm-center mb-5 mb-md-0">
                                            <span className="d-block display-md mb-2 mb-md-5">{data.section_6_counter_1_number.replace('.', ',')}</span>
                                            <span className="d-block font-size-lg mb-2">{data.section_6_counter_1_subtitle}</span>
                                            <span className="d-block display-4">{data.section_6_counter_1_title}</span>
                                        </Col>
                                        <Col sm={6} className="text-left text-sm-center animation-flicker-stop">
                                            <span className="d-block display-md mb-2 mb-md-5">{data.section_6_counter_2_text}</span>
                                            <span className="d-block font-size-lg mb-2">{data.section_6_counter_2_subtitle}</span>
                                            <span className="d-block display-4">{data.section_6_counter_2_title}</span>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        )
                    })}
                </Slider>
            </section>
        )
    }
}

export default ProcessBenefit;