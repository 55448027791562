import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import together from '../../../assets/imgs/process/together.jpeg';
import Fade from 'react-reveal/Fade';
import './together.scss';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';


class ProcessTogether extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cauntA: true,
            cauntB: true
        }
    }

    onChangeA = (e) => {
        if (e && this.state.cauntA) {
            this.myCountUpA.restart();
            this.setState({ cauntA: false });
        }
    }
    onChangeB = (e) => {
        if (e && this.state.cauntB) {
            this.myCountUpB.restart();
            this.setState({ cauntB: false });
        }
    }
    render() {
        return (
            <section className="bg-primary text-white h-vh cmnPmH position-relative mob-height wp-together py-0 d-flex flex-column align-items-center justify-content-center">
                <Fade>
                    <img alt="" src={this.props.data.media.image[0]} width="50%" height="100%" className="object-cover position-absolute right wp-v-f-img animation-flicker-stop" />
                </Fade>
                <Container>
                    <Row className="h-100">
                        <Col sm={8} md={9} className="mt-4 mb-5 animation-flicker-stop description">
                            <Fade bottom>
                                <span className="d-block display-1 mt-0 mt-md-5 animation-flicker-stop">{this.props.data.title}</span>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
                <Container fluid="true" className="mt-5 animation-flicker-stop">
                    <Row>
                        <Col xs={6} className="text-center">
                            <span className="d-block display-md mb-3 mb-md-4">
                                <VisibilitySensor onChange={this.onChangeA}>
                                    <CountUp
                                        start={0}
                                        end={parseInt(this.props.data.counter[0].value)}
                                        duration={5}
                                        separator=" "
                                        decimals={0}
                                        decimal="."
                                        suffix=""
                                        ref={countUp => { this.myCountUpA = countUp; }}
                                    >
                                    </CountUp>
                                </VisibilitySensor>
                            </span>
                            <span className="d-block font-size-lg mb-2">{this.props.data.counter[0].sub_title}</span>
                            <span className="d-block display-4">{this.props.data.counter[0].title}</span>
                        </Col>
                        <Col xs={6} className="text-center animation-flicker-stop">
                            <span className="d-block display-md mb-3 mb-md-4">
                                <VisibilitySensor onChange={this.onChangeB}>
                                    <CountUp
                                        start={0}
                                        end={parseInt(this.props.data.counter[1].value)}
                                        duration={5}
                                        separator=" "
                                        decimals={0}
                                        decimal="."
                                        suffix=""
                                        ref={countUp => { this.myCountUpB = countUp; }}
                                    >
                                    </CountUp>
                                </VisibilitySensor>
                            </span>
                            <span className="d-block font-size-lg mb-2">{this.props.data.counter[1].sub_title}</span>
                            <span className="d-block display-4">{this.props.data.counter[1].title}</span>
                        </Col>
                    </Row>
                </Container>

            </section >
        )
    }
}



export default ProcessTogether;