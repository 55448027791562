import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './earth.scss';

class WiserWashEarth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            network: '',
        };
        this.setStaticVaribale();
    }
    setStaticVaribale = () => {
        const netWork = {
            production: this.props.data.production_list,
            soon: this.props.data.soon_to_follow
        }
        this.state.network = netWork;
    }
    render() {
        return (
            <section className="bg-primary mob-height cmnPmH h-vh position-relative text-white d-flex align-items-center earth" >
                <Fade>
                    <img alt="" src={this.props.data.image} className="object-cover position-absolute align-self-center right h-75 cover-img" />
                </Fade>
                <Container className="h-75 animation-flicker-stop" fluid="true">
                    <Row className="h-100 align-items-end">
                        <Col md={6} sm={6} xs={8} className="pb-md-5 pb-4 pl-xl-5">
                            <Fade bottom>
                                <span className="d-block display-4 sw-75 title animation-flicker-stop">{this.props.data.title}</span>
                            </Fade>
                        </Col>
                        <Col md={6} sm={10} className="bg-primary global-country animation-flicker-stop">
                            <Fade bottom cascade>
                                <ul className="mb-0">
                                    <li><small className="d-block mb-2">{this.props.data.production_title}</small></li>
                                    {this.state.network.production.map((item, index) => {
                                        return <li className="d-flex align-items-center" key={index}>
                                            <span className="d-block country display-4 sw-60">{item.country}</span>
                                            <span className="d-block text-uppercase font-heading h3 mb-0 text-white">{item.city}</span>
                                        </li>
                                    })}

                                    <li><small className="d-block mb-2 mt-3">{this.props.data.soon_to_follow_title}</small></li>
                                    {this.state.network.soon.map((item, index) => {
                                        return <li className="d-flex align-items-center" key={index}>
                                            <span className="d-block country display-4 sw-60">{item.country}</span>
                                            <span className="d-block text-uppercase font-heading h3 mb-0 text-white">{item.city}</span>
                                        </li>
                                    })}
                                </ul>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section >
        )
    }
}



export default WiserWashEarth;