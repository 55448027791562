import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './process-landing.scss';
import { Link } from "react-router-dom";
import ReactSVG from 'react-svg';

import downArrow from '../../../assets/imgs/icons/down-arrow.svg';
import * as actions from '../../../store/actions/index';
import TabMenu from '../../tab-menu/tab-menu';

class ProcessLanding extends Component {

    constructor(props) {
        super(props);
        this.keyDown = this.keyDown.bind(this);
        this.touchScroll = this.touchScroll.bind(this);
    }

    createMarkup(data) {
        return { __html: data };
    }

    wheel(e) {
        if (e.deltaY > 0) {
            this.props.landingScroll({ 'landingScroll': true });
        } else {
            if (this.props.currentPage === 1 && document.getElementById('closeBtn')) {
                document.getElementById('closeBtn').click();
            }
        }
    }

    keyDown(e) {
        if (e.keyCode == 40) {
            this.props.landingScroll({ 'landingScroll': true });
        }
        if (e.keyCode == 38 && this.props.currentPage === 1 && document.getElementById('closeBtn')) {
            document.getElementById('closeBtn').click();
        }
    }

    touchScroll(e) {
        let isCloseBtn = document.getElementById('closeBtn');
        if (window.scrollY > 50 && !isCloseBtn) {
            this.props.landingScroll({ 'landingScroll': true });
        }

        if (window.scrollY < 10 && this.props.currentPage === 1 && isCloseBtn) {
            document.getElementById('closeBtn').click();
        }
    }


    componentDidMount() {
        window.addEventListener('keydown', this.keyDown);
        window.addEventListener('scroll', this.touchScroll);

        setTimeout(() => {
            document.getElementsByClassName('cmnPmH')[0].parentNode.parentNode.classList.add("parentSec");
            document.getElementsByClassName('cmnPmH')[0].parentNode.parentNode.parentNode.classList.add("stopScroll");
        })
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDown);
        window.removeEventListener('scroll', this.touchScroll);
    }

    render() {
        return (
            <section className={'h-vh landing cmnPmH process-landing mob-height bg-white ' + (this.props.landingScrollAction !== undefined && this.props.landingScrollAction.landingScroll ? 'change-bg' : '')} onWheel={(e) => this.wheel(e)}>
                <span className="line wwp-line"></span>
                <span className="line wwp2-line"></span>
                <span className="line wwp3-line"></span>
                <Container>
                    <article className="d-none d-md-block">
                        <Fade bottom>
                            <img alt="" src={this.props.data.media.images[0]} className="m-auto object-cover wp-l-b-img position-absolute left bottom" />
                        </Fade>
                    </article>
                    <div className="position-absolute right top mt-5 process-landing-right d-none d-md-block">
                        <article className="h-10vh mt-5 mb-3 mb-lg-5 wp-l-s-img-wrap">
                            <Fade top>
                                <img alt="" src={this.props.data.media.images[1]} className="object-cover" />
                            </Fade>
                        </article>
                        <Fade bottom>
                            <span className="d-block text-primary sw-90">{this.props.data.description}</span>
                        </Fade>
                    </div>
                    <Row>
                        <Col xs={12} sm={6} md={7} className="d-flex align-items-center mt-0 mt-lg-5 landing-description">
                            <Fade left cascade>
                                <figure className="mb-0 mt-0 mt-sm-5">
                                    <h1 className="d-block display-1 text-primary mt-0 mt-lg-5"
                                        dangerouslySetInnerHTML={this.createMarkup(this.props.data.title)}></h1>
                                </figure>
                            </Fade>
                        </Col>
                        <Col xs={12} sm={6} className="d-block d-md-none">
                            <article className="h-10vh mt-5 mb-3 mb-lg-5 wp-l-s-img-wrap">
                                <Fade top>
                                    <img alt="" src={this.props.data.media.images[1]} className="object-cover" />
                                </Fade>
                            </article>
                            <Fade bottom>
                                <span className="d-block text-primary sw-90">{this.props.data.description}</span>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

                {/* tab Menu start */}
                {this.props.landingScrollAction && this.props.landingScrollAction.landingScroll && this.props.width > 1024 ? (
                    <Link onClick={() => this.props.goToSection({ 'downArrow': 1 })} to="/process"><ReactSVG className="pulse-arrow m-auto" src={downArrow} style={{ width: 32 + 'px' }} /></Link>
                ) : (
                        <div className={this.props.landingScrollAction && this.props.landingScrollAction.landingScroll ? 'blue-nav' : ''}>
                            <TabMenu></TabMenu>
                        </div>
                    )}
                {/* tab Menu end */}

            </section>

        )
    }
}

const mapStateToProps = state => {
    //Manp the propps for local state
    return {
        header_close_action: state.uiProps.header_close_action,
        landingScrollAction: state.uiProps.landing_scroll_action,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        landingScroll: (details) => dispatch(actions.landingScrollEvent(details)),
        goToSection: (details) => dispatch(actions.changeHeaderCloseBtn(details)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessLanding);