import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import location from './location.svg';

const MapMarker = ({ url }) => <div><img alt="" src={url} /></div>;

class MapContainer extends Component {
    render() {
        return (
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
                defaultCenter={{ lat: parseFloat(this.props.lat), lng: parseFloat(this.props.long) }}
                defaultZoom={8}
                options={createMapOptions}>
                <MapMarker
                    lat={this.props.lat}
                    lng={this.props.long}
                    url={location}
                />
            </GoogleMapReact>
        );
    }
}

export default MapContainer

function createMapOptions(maps) {
    return {
        disableDefaultUI: true,
        styles: [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.9 }, { 'lightness': 5 }, { 'visibility': 'on' }] }]
    };
}