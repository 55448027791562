import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './wise-up.scss';

class WiserWashWiseUp extends Component {

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section className="wise-up bg-primary mob-height cmnPmH h-vh position-relative text-white">
                {/* for desktop version start */}
                <Container className="h-100 d-md-block d-none">
                    <Row className="h-100">
                        <Col md={6} className="d-flex align-items-center">
                            <Fade bottom cascade>
                                <figure className="mb-0">
                                    <span className="d-block display-1 mb-5 title" dangerouslySetInnerHTML={this.createMarkup(this.props.data.title)}></span>
                                    <span className="d-block sw-50 des">{this.props.data.description}</span>
                                </figure>
                            </Fade>
                        </Col>
                        <Col md={6} className="d-flex align-items-center">
                            <Fade bottom>
                                <img alt="" src={this.props.data.media.images[1]} className="object-cover summerTime" width="100%" />
                            </Fade>
                            <Fade bottom>
                                <img alt="" src={this.props.data.media.images[0]} className="position-absolute bottom z-index-1 object-contain wwJeans" />
                            </Fade>
                        </Col>
                    </Row>
                </Container>
                {/* for desktop version end */}

                <Container className="d-md-none d-block">
                    <Row>
                        <Col sm={{ span: 7, offset: 5 }} xs={{ span: 8, offset: 4 }}>
                            <Fade bottom>
                                <img alt="" src={this.props.data.media.images[1]} className="object-cover cover-img" width="100%" />
                            </Fade>
                        </Col>
                        <Col xs={7}>
                            <Fade bottom>
                                <img alt="" src={this.props.data.media.images[0]} className="absolute-img" width="100%" />
                            </Fade>
                        </Col>
                        <Col xs={5}>
                            <Fade bottom>
                                <span className="d-block display-1 title">WISE UP.<br />WEAR WISER.</span>
                            </Fade>
                        </Col>
                        <Col xs={12} className="mt-5">
                            <Fade bottom>
                                <span className="d-block des">{this.props.data.description}</span>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}



export default WiserWashWiseUp;