import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './changes-that.scss';



class ProcessChanges extends Component {

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section className="bg-primary text-white h-vh cmnPmH position-relative changes-that">
                <Container>
                    <Row>
                        <Col xl={6} md={5} xs={6} className="ww-gap-lg px-0 pl-md-0 pl-xl-3">
                            <Fade bottom >
                                <img alt="" src={this.props.data.media.images[0]} className="m-xl-auto object-cover ww-l-l-img mb-4 mb-md-0" />
                            </Fade>
                        </Col>
                        <Col md={6} xs={12} className="d-flex align-items-center description">
                            <Fade bottom cascade>
                                <figure className="mb-0">
                                    <span className="d-block font-size-lg sw-85 mb-3 mb-lg-5">{this.props.data.description}</span>
                                    <span className="d-block display-1" dangerouslySetInnerHTML={this.createMarkup(this.props.data.title)}></span>
                                </figure>
                            </Fade>
                        </Col>
                    </Row>

                    <article className="d-none d-md-block">
                        <Fade bottom>
                            <img alt="" src={this.props.data.media.images[1]} className="position-absolute left wp-l-b-s-img" />
                        </Fade>
                    </article>

                    <article className="opacity-05">
                        <Fade bottom>
                            <span className="d-block font-size-sm position-absolute floating-text bottom right mt-4 mt-md-0 mb-0 mb-md-4 mr-4">{this.props.data.sub_description}</span>
                        </Fade>
                    </article>

                </Container>

            </section>
        )
    }
}



export default ProcessChanges;