import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './indigo.scss';


class WiserWashIndigo extends Component {

    render() {
        return (
            <section className="bg-primary mob-height cmnPmH h-vh position-relative text-white d-flex align-items-center indigo">
                <Fade>
                    {/* <img alt="" src={indigo} width="50%" height="100%" className="object-cover position-absolute cover-img d-md-block d-none" /> */}
                    <img alt="" src={this.props.data.media.images[0]} width="50%" height="100%" className="object-cover position-absolute cover-img d-md-block d-none" />
                </Fade>
                <Container className="d-md-block d-none animation-flicker-stop">
                    <Row>
                        <Col md={{ span: 6, offset: 6 }} className="d-flex justify-content-end">
                            <Fade bottom>
                                <span className="d-block content-rw-100 des">{this.props.data.description}</span>
                            </Fade>
                        </Col>
                        <Col md={{ span: 8, offset: 4 }}>
                            <Fade bottom>
                                <sub className="d-block display-3 animation-flicker-stop">"</sub>
                                <span className="d-block display-1 mb-5 titile animation-flicker-stop">{this.props.data.title}</span>
                            </Fade>
                        </Col>
                        <Col md={{ span: 6, offset: 6 }} className="d-flex justify-content-end">
                            <Fade bottom>
                                <span className="d-flex content-rw-100 align-items-center">
                                    <small className="mr-2">{this.props.data.media.signature_text} </small>
                                    {/* <img alt="" src={pepeJeans} /> */}
                                    <img alt="" src={this.props.data.media.signature_image} />
                                </span>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

                {/* for mobile start */}
                <Container className="d-md-none d-block mb-5">
                    <Fade bottom>
                        <span className="d-block content-rw-100 des">{this.props.data.description}</span>
                    </Fade>
                </Container>
                <Container className="px-0 d-md-none d-block">
                    <Row>
                        <Col xs={7} className="d-flex justify-content-end">
                            <Fade>
                                <img alt="" src={this.props.data.media.images[0]} width="100%" height="100%" className="object-cover cover-img" />
                            </Fade>

                        </Col>
                        <Col xs={5} className="d-flex flex-column justify-content-end">
                            <Fade bottom cascade>
                                <figure className="content">
                                    <sub className="d-block display-3">"</sub>
                                    <span className="d-block display-1 mb-3 titile">{this.props.data.title}</span>
                                    <span className="d-flex content-rw-100 align-items-center"><small className="mr-2">{this.props.data.media.signature_text}</small> <img alt="" src={this.props.data.media.signature_image} /></span>
                                </figure>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
                {/* for mobile end */}

            </section>
        )
    }
}

export default WiserWashIndigo;