import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './born.scss';



class ProcessBorn extends Component {

    render() {
        return (
            <section className="bg-primary text-white h-vh cmnPmH position-relative mob-height pb-5 wp-born">
                <Container className="h-100 d-flex align-items-center">
                    <Row>
                        <Col md={12} className="mt-0 mt-sm-5 mt-xl-5 mb-4 mb-md-5 pl-xl-0">
                            <Fade bottom>
                                <span className="d-block display-md-x mt-0 mt-xl-5">{this.props.data.title}</span>
                            </Fade>
                        </Col>
                        <Col md={6} lg={5} className="ww-gap-lg mb-3 mb-md-0">
                            <Fade bottom>
                                <span className="d-block font-size-xl">{this.props.data.description}</span>
                            </Fade>
                        </Col>
                        <Col md={{ span: 6, offset: 0 }} lg={{ span: 5, offset: 2 }}>
                            <Fade bottom>
                                <span className="d-block">{this.props.data.sub_description}</span>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

            </section>
        )
    }
}



export default ProcessBorn;