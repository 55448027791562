import React, { Component } from 'react';
import './index.scss';
import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";



// import page
import WiserWash from '../pages/wiser-wash/wisher-wash';
import Process from '../pages/process/process';
import MainHeader from '../../components/header/header';


import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';




class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: 0,
			network: ''
		};
		this._pageScroller = null;
		this.setStaticVaribale();
	}
	setStaticVaribale = () => {
		const netWork = {
			production: [
				{
					country: 'TURKEY',
					state: 'IZMIR',
				},
				{
					country: 'PORTUGAL',
					state: 'PORTO',
				},
				{
					country: 'USA',
					state: 'LA',
				}
			],
			soon: [
				{
					country: 'VIETNAM',
					state: 'DANANG',
				},
				{
					country: 'INDIA',
					state: 'BANGALORE',
				},
				{
					country: 'MAURITIUS',
					state: 'MAURITIUS',
				},
				{
					country: 'CHINA',
					state: 'CHINA',
				}
			]
		}
		this.state.network = netWork;
	}


	componentDidMount() {
		console.log("I am in componentDidMount");
		Events.scrollEvent.register('begin', function (to, element) {
			console.log("begin", to, element);
		});

		Events.scrollEvent.register('end', function (to, element) {
			console.log("end", to, element);
		});

		scrollSpy.update();
	}



	/**
	 * 
	 * **updateState**get the currentpage status from wisherwash component
	 * and pass the data mainheader component.
	 */
	updateState($event) {
		this.setState({ currentPage: $event });
	}


	render() {
		return (
			<div>
				{/* header start */}
				<MainHeader headerProps={this.state.currentPage}></MainHeader>
				{/* header end */}
				{/* wiser wash */}
				{/* <WiserWash updateStates={this.updateState.bind(this)}></WiserWash> */}
				{/* wiser wash */}
				{/* wiser wash */}
				<Process updateStates={this.updateState.bind(this)}></Process>
				{/* wiser wash */}

			</div>
		);
	};
}
const goToPage = (pageNumber) => {
	this.reactPageScroller.goToPage(pageNumber);
}
// const mapStateToProps = state => {
// 	//Manp the propps for local state
// }

// const mapDispatchToProps = dispatch => {
// 	return {
// 		// 
// 	}
// }


export default Index;