import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';


class ProcessCounter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cauntA: true
        }
    }
    onChangeA = (e, index) => {
        if (e && this.state.cauntA) {
            for (let i = 0; i < this.myCountUpA.length; i++) {
                this.myCountUpA[i].restart();
            }
            this.setState({ cauntA: false });
        }
    }

    render() {
        return (
            <section className="bg-primary cmnPmH h-vh position-relative text-white d-flex align-items-center text-center mob-height">
                <Container>
                    <Row>
                        {
                            this.props.data.counter.map((data, index) => {
                                this.myCountUpA = [];
                                return (
                                    <Col sm={6} md={4} className="my-3 my-md-5" key={'process-counter-' + index}>
                                        <Fade>
                                            <span className="d-block display-sm mb-2 mb-md-3 mb-lg-4">
                                                <VisibilitySensor onChange={(event) => this.onChangeA(event, index)}>
                                                    <CountUp
                                                        start={100}
                                                        end={parseInt(data.value)}
                                                        duration={5}
                                                        separator=" "
                                                        decimals={0}
                                                        decimal="."
                                                        suffix="%"
                                                        ref={countUp => { this.myCountUpA[index] = countUp; }}
                                                    >
                                                    </CountUp>
                                                </VisibilitySensor>
                                            </span>
                                            <span className="d-block font-size-lg">{data.title}</span>
                                        </Fade>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </section>
        )
    }
}

export default ProcessCounter;