import React, { Component } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './wise-up.scss';
import ContactForm from '../../../components/contact/contact-form';
import crossArrow from '../../../assets/imgs/icons/cross.svg';
import ReactSVG from 'react-svg';

class WiseUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageData: null,
            modalShow: false,
        };
    }

    setModalShow = (param) => {
        this.setState({ modalShow: param });
    }

    onMessageSent(message) {
        this.setState({ modalMessage: message });
        this.setModalShow(true);
    }

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section className="h-vh cmnPmH mob-height partner-wise-up bg-primary">
                <div className="primary-box bg-dark-o-1">
                    <Container className="py-5">
                        <Row className="h-100 mb-0 mb-xl-5">
                            <Col md={6} className="d-flex justify-content-center flex-column">
                                <Fade bottom cascade>
                                    <figure className="mb-0 mb-xl-5">
                                        <h2 className="d-block display-1 title text-white mb-4" dangerouslySetInnerHTML={this.createMarkup(this.props.data.sub_title)}></h2>
                                    </figure>
                                </Fade>
                                <Fade bottom>
                                    <h3 className="d-block display-3 text-white mb-3" dangerouslySetInnerHTML={this.createMarkup(this.props.data.title)}></h3>
                                </Fade>
                            </Col>
                            <Col md={6}>
                                <Fade bottom>
                                    <ContactForm data={this.props.data} onSuccess={this.onMessageSent.bind(this)}></ContactForm>
                                </Fade>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <StatusModal show={this.state.modalShow} message={this.state.modalMessage} onHide={() => this.setModalShow(false)} animation={true} />
            </section>

        )
    }
}
function StatusModal(props) {
    return (
        <Modal {...props} size="sm" centered>
            <Button onClick={props.onHide} className="modal-close">
                <ReactSVG src={crossArrow} style={{ width: 12 + 'px' }} />
            </Button>
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onHide}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}
export default WiseUp;

