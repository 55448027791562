import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import ContactForm from '../../../components/contact/contact-form';
import './contact.scss';
import MapContainer from '../../../components/google-map/google-map'
import crossArrow from '../../../assets/imgs/icons/cross.svg';
import ReactSVG from 'react-svg';
import Map from '../../../assets/imgs/map.jpg';

import { connect } from 'react-redux';
import TitleComponent from '../../../components/title/title';


class Contact extends Component {
	static defaultProps = {
		center: {
			lat: 59.95,
			lng: 30.33
		},
		zoom: 11
	};

	constructor(props) {
		super(props);
		this.state = {
			currentPage: 0,
			scrollMode: false,
			modalShow: false,
			modalMessage: '',
			pageData: null
		};
		this.touchScroll = this.touchScroll.bind(this);
		const _header = ''
	}

	createMarkup(data) {
		return { __html: data };
	}

	setModalShow = (param) => {
		this.setState({ modalShow: param });
	}

	/**
	 * section onchange get event and update state for current section noumber.
	 * add all scetion a coomon class and active class for current section.
	 */
	pageOnChange = (number) => {
		// this.setState({scrollMode:true})
		if (document.getElementsByClassName('activeSection').length > 0) {
			document.getElementsByClassName('activeSection')[0].classList.remove("activeSection");
		};
		document.getElementsByClassName('cmnPmH')[number - 1].parentNode.classList.add("activeSection", "cmnPmSec");
		setTimeout(() => {
			this.setState({ currentPage: number });
			this.props.updateStates(number);
		}, 600);

	};


	/**
	 * **gotToSection** handling to go section through the function.
	 */
	gotToSection(eventKey) {
		if (eventKey.closeBtn === 0) this._pageScroller.goToPage(eventKey.closeBtn);
		if (eventKey.downArrow) this._pageScroller.goToPage(eventKey.downArrow);
	}

	/**
	 * 
	 * @param {*} touchScroll 
	 * this function handling contact page blue screen header theming by adding class.
	 */
	touchScroll(e) {
		const targetDiv = document.getElementsByClassName('bg-primary cmnPmH');
		const _header = document.getElementsByClassName('headerWrap')[0];
		const _headerHeight = _header.clientHeight;
		for (let i = 0; i < targetDiv.length; i++) {
			// console.log(window.screenTop, targetDiv[i].offsetTop);
			const _target = targetDiv[i];
			const _ofset = _target.offsetTop - _headerHeight;
			const _height = _target.clientHeight;
			const _offsetNDHeight = _ofset + _height;

			if (window.scrollY > _ofset && window.scrollY <= _offsetNDHeight) {
				_target.classList.add('targetDiv');
			} else {
				_target.classList.remove('targetDiv');
			}
			const targetL = document.getElementsByClassName('targetDiv').length;
			if (targetL > 0) {
				_header.classList.add('activeMode');
			} else {
				_header.classList.remove('activeMode');
			}
		}
	}

	componentDidMount() {
		document.getElementsByTagName('body')[0].classList.add('contactPage');
		window.addEventListener('scroll', this.touchScroll);

		const contactEls = document.getElementsByClassName('contact-content');
		for (let el of contactEls) {
			if (!el.hasChildNodes()) {
				el.style.display = 'none';
			}
		}

		axios.post('/wiser_contact', { section: 'ALL' }).then(response => {
			this.setState({ pageData: response.data.page });
		})
	}

	componentWillUnmount() {
		document.getElementsByTagName('body')[0].classList.remove('contactPage');
		window.removeEventListener('scroll', this.touchScroll);
	}

	onMessageSent(message) {
		this.setState({ modalMessage: message });
		this.setModalShow(true)
	}

	parseOfficeTime(date) {
		const newDate = new Date(date);
		return newDate.getHours().toLocaleString('en-US', { minimumIntegerDigits: 2 }) + ':' + newDate.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2 });
	}

	render() {
		const title = 'Get in touch | Wiser Wash';
		const description = 'Wash away all your sins, be wiser and contact us now. Lets do good together. Mail: info@winderglobe.com; Address: Hannie Dankbaarpassage 18b, 1053RT Amsterdam, The Netherlands.';

		if (this.props.header_close_action !== undefined) {
			this.gotToSection(this.props.header_close_action)
		}

		const section = this.state.pageData !== null ? (
			<div className="contact-content bg-white">
				<span className="line wwc-line"></span>

				<TitleComponent title={title} description={description} />
				<section className="cmnPmH d-flex position-relative py-sec">
					<Container className="pt-3 pt-lg-5">
						<Row>
							<Col md={6} className="d-flex align-items-end">
								<Fade bottom>
									<h1 className="d-block display-1 mb-0 text-primary">{this.state.pageData.section_1.title}</h1>
								</Fade>
							</Col>
							<Col md={6} className="d-flex align-items-end">
								<Fade bottom cascade>
									<span className="d-block mt-3 mt-md-5" dangerouslySetInnerHTML={this.createMarkup(this.state.pageData.section_1.description)}></span>
								</Fade>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-primary cmnPmH position-relative text-white py-sec">
					<span className="line wwc2-line"></span>
					<Container>
						<Row>
							<Col sm={4} className="mb-4 mb-sm-0">
								<Fade bottom>
									<div className="mb-2">{this.state.pageData.section_2.office_time_label}</div>
									<span className="d-block display-1 mb-2 mb-sm-4 text-white">{this.state.pageData.section_4.offices[0].get_date}</span>
									{/* <div className="mb-2 mb-sm-3">+1 900 123 123 12</div> */}
									<a className="text-white d-block" href={"mailto:" + this.state.pageData.section_4.offices[0].email_address}>{this.state.pageData.section_4.offices[0].email_address}</a>
								</Fade>
							</Col>
							<Col sm={8}>
								<Fade bottom>
									<div className="mb-2">{this.state.pageData.section_2.office_open_label}</div>
									<span className="d-block display-1 mb-2 mb-sm-4 text-white">{this.state.pageData.section_4.offices[0].place_name}{this.state.pageData.section_4.offices[0].is_hq ? ' HQ' : ''}</span>
									<div className="mb-2 mb-sm-3">{this.state.pageData.section_4.offices[0].address_line_1}</div>
									<div className="text-white">{this.state.pageData.section_4.offices[0].address_line_2}</div>
								</Fade>
							</Col>
						</Row>
					</Container>
				</section>

				{/* start map section */}
				<section className="cmnPmH position-relative mapContainer z-index-1">
					<a href="https://goo.gl/maps/1GJvdkDmvXPQfiQr5" target="_blank">
						<img src={Map} className="object-cover overflow-hidden" width="100%" height="100%" />
					</a>
					{/* <MapContainer lat={this.state.pageData.section_4.offices[0].latitude} long={this.state.pageData.section_4.offices[0].longitude} ></MapContainer> */}
				</section>
				{/* end map section */}

				<section className="cmnPmH position-relative py-sec">
					<Container>
						<Row>
							<Col md={6}>
								<Fade bottom>
									<h3 className="d-block display-3 text-primary mb-3 mb-md-5">{this.state.pageData.section_3.title}</h3>
								</Fade>
							</Col>
							<Col md={6}>
								<Fade bottom>
									<ContactForm data={this.state.pageData.section_3} onSuccess={this.onMessageSent.bind(this)}></ContactForm>
								</Fade>
							</Col>
						</Row>
					</Container>
				</section>

				<section className="bg-primary cmnPmH position-relative text-white py-sec office-list">
					<Container>
						<Fade bottom>
							<div className="mb-2">
								<Row>
									<Col xs={5} sm={3}>
										<div>{this.state.pageData.section_4.office_time_label}</div>
									</Col>
									<Col xs={7} sm={6}>
										<div>{this.state.pageData.section_4.office_open_label}</div>
									</Col>
									{/* <Col sm={2}>
										<div>Address</div>
									</Col>
									<Col sm={1}>
										<div >Contact</div>
									</Col> */}
								</Row>
							</div>
							{this.state.pageData.section_4.offices.map((office, index) => {
								const isOpenClass = office.is_open ? 'text-white' : 'text-secondary';
								return (
									<Row key={'office-list-' + index}>
										<Col xs={5} sm={3}>
											{/* <div className="d-sm-none">Office Time</div> */}
											<span className={'d-block display-1 ' + ' ' + isOpenClass}>{office.get_date}</span>
										</Col>
										<Col xs={7} sm={6}>
											{/* <div className="d-sm-none">Office Open</div> */}
											<span className={'d-block display-1 ' + ' ' + isOpenClass}>{office.place_name}{office.is_hq ? ' HQ' : ''}</span>
										</Col>
										{/* <Col xs={5} sm={2} className="mt-3  mt-sm-0">
											<div className="d-sm-none">Address</div>
											<span className="d-block my-2 text-white"></span>
										</Col>
										<Col xs={7} sm={1} className="mt-3 mt-sm-0">
											<div className="d-sm-none">Contact</div>
											<span className="d-block my-2 text-white">Contact@</span>
										</Col> */}
									</Row>
								);
							})}
						</Fade>
					</Container>

				</section>

				<StatusModal show={this.state.modalShow} message={this.state.modalMessage} onHide={() => this.setModalShow(false)} animation={true} />
			</div>
		) : null;

		return section;
	}
}

function StatusModal(props) {
	return (
		<Modal {...props} size="sm" centered>
			<Button onClick={props.onHide} className="modal-close">
				<ReactSVG src={crossArrow} style={{ width: 12 + 'px' }} />
			</Button>
			<Modal.Body>{props.message}</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={props.onHide}>OK</Button>
			</Modal.Footer>
		</Modal>
	);
}

const mapStateToProps = state => {
	//Manp the propps for local state
	return {
		header_close_action: state.auth.header_close_action
	}
}


const mapDispatchToProps = dispatch => {
	return {
		// 
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);