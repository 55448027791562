import React, { Component } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import videoAvatar from '../../../assets/imgs/process/video-avatar.jpeg';
import Fade from 'react-reveal/Fade';
import ReactSVG from 'react-svg';
import crossArrow from '../../../assets/imgs/icons/cross.svg';
import play from '../../../assets/imgs/icons/play.svg';
import { Link } from "react-router-dom";
import "./indigo.scss";

class ProcessIndigo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalShow: false
        };
    }

    setModalShow = (param) => {
        this.setState({ modalShow: param });
    }

    render() {
        return (
            <section className="bg-primary text-white h-vh cmnPmH position-relative pt-5 mob-height wp-indigo">
                <Container className="h-100">
                    <Row className="h-100">
                        <Col sm={6} md={7} className="d-flex align-items-start mt-0 mt-md-5 ww-gap-lg">
                            <Fade bottom cascade>
                                <figure className="mb-0 mt-0 mt-md-5">
                                    <span className="d-block display-1 mt-0 mt-sm-5">{this.props.data.title}</span>
                                </figure>
                            </Fade>
                        </Col>
                        <Col sm={6} md={5} className="d-flex flex-column justify-content-center align-items-start">
                            <Fade bottom cascade>
                                <div className="video-holder my-4 my-md-5">
                                    <Link to="/process" onClick={() => this.setModalShow(true)} className="d-block position-relative">
                                        <span className="play">
                                            <ReactSVG src={play} style={{ width: 18 + 'px' }} />
                                        </span>
                                        <img alt="" src={this.props.data.media.images[0]} className="m-xl-auto object-cover shadow" />
                                    </Link>
                                </div>
                            </Fade>
                            <Fade bottom>
                                <span className="d-block">{this.props.data.description}</span>
                            </Fade>
                        </Col>
                    </Row>

                </Container>

                {/* video modal */}
                <VideoModal
                    videolink={this.props.data.media.vidoes[0]}
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                />

            </section>
        )
    }
}

function VideoModal(props) {
    return (
        <Modal {...props} size="lg" centered>
            <Button onClick={props.onHide} className="modal-close">
                <ReactSVG src={crossArrow} style={{ width: 12 + 'px' }} />
            </Button>
            <Modal.Body>
                <iframe id="ytplayer" type="text/html" width="100%" height="405"
                    src={props.videolink + '?autoplay=1&disablekb=1'}
                    frameBorder="0" allowFullScreen></iframe>
            </Modal.Body>
        </Modal>
    );
}

export default ProcessIndigo;