import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as actions from '../../../store/actions/index';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import './partner-landing.scss';
import TabMenu from '../../tab-menu/tab-menu';
import { Link, withRouter } from "react-router-dom";
import ReactSVG from 'react-svg';
import downArrow from '../../../assets/imgs/icons/down-arrow.svg';

class PartnerLanding extends Component {

    constructor(props) {
        super(props);
        this.keyDown = this.keyDown.bind(this);
        this.touchScroll = this.touchScroll.bind(this);
    }

    createMarkup(data) {
        return { __html: data };
    }

    wheel(e) {
        let isCloseBtn = document.getElementById('closeBtn');
        if (e.deltaY > 0) {
            this.props.landingScroll({ 'landingScroll': true });
        } else {
            if (this.props.currentPage === 1 && isCloseBtn) {
                document.getElementById('closeBtn').click();
            }
        }
    }

    keyDown(e) {
        if (e.keyCode == 40) {
            this.props.landingScroll({ 'landingScroll': true });
        }
        if (e.keyCode == 38 && this.props.currentPage === 1 && document.getElementById('closeBtn')) {
            document.getElementById('closeBtn').click();
        }
    }

    touchScroll(e) {
        let isCloseBtn = document.getElementById('closeBtn');
        if (window.scrollY > 20 && !isCloseBtn) {
            this.props.landingScroll({ 'landingScroll': true });
        }

        if (window.scrollY < 10 && this.props.currentPage === 1 && isCloseBtn) {
            document.getElementById('closeBtn').click();
        }
    }


    componentDidMount() {
        window.addEventListener('keydown', this.keyDown);
        window.addEventListener('scroll', this.touchScroll);

        setTimeout(() => {
            document.getElementsByClassName('cmnPmH')[0].parentNode.parentNode.classList.add("parentSec");
            document.getElementsByClassName('cmnPmH')[0].parentNode.parentNode.parentNode.classList.add("stopScroll");
        })
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDown);
        window.removeEventListener('scroll', this.touchScroll);
    }

    render() {
        return (
            <section className={'h-vh landing cmnPmH mob-height d-flex align-items-start align-items-sm-center position-relative bg-white ' + (this.props.landingScrollAction !== undefined && this.props.landingScrollAction.landingScroll ? 'change-bg' : '')} onWheel={(e) => this.wheel(e)}>
                <span className="line wwl-line"></span>
                {/* left image start */}
                <article className="d-none d-md-block">
                    <Fade bottom>
                        <img alt="" src={this.props.data.images[1]} className="object-cover position-absolute left bottom wpt-l-s-img" />
                    </Fade>
                </article>
                {/* left image end */}

                {/* right image start */}
                <article className="d-none d-md-block">
                    <Fade right>
                        <img alt="" src={this.props.data.images[0]} className="object-cover position-absolute right top mt-5 pt-5 wpt-r-b-img" />
                    </Fade>
                </article>
                {/* right image end */}

                <Container className="py-3 partner-landing-text">
                    <Row>
                        <Col xs={{ span: 6, offset: 6 }} className="d-block d-md-none mt-md-5 pr-0 top-image">
                            <Fade right>
                                <img alt="" src={this.props.data.images[0]} width="35%" height="100%" className="magic img m-xl-auto object-cover ww-l-l-img mb-4" />
                            </Fade>
                        </Col>
                        <Col xs={2} className="d-block d-md-none p-0 bottom-image">
                            <article>
                                <Fade bottom>
                                    <img alt="" src={this.props.data.images[1]} className="object-cover ww-lr-img position-absolute top left" />
                                </Fade>
                            </article>
                        </Col>
                        <Col xs={9} sm={{ span: 7, offset: 2 }} md={{ span: 8, offset: 2 }} lg={{ span: 7, offset: 3 }} xl={{ span: 8, offset: 2 }} className="description">
                            <Fade left cascade>
                                <h1 className="d-block display-1 title text-primary text-right text-sm-left mb-0" dangerouslySetInnerHTML={this.createMarkup(this.props.data.title)}></h1>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

                {/* tab Menu start */}
                {this.props.landingScrollAction && this.props.landingScrollAction.landingScroll && this.props.width > 1024 ? (
                    <Link onClick={() => this.props.goToSection({ 'downArrow': 1 })} to={'/partner/' + this.props.match.params.tab}><ReactSVG className="pulse-arrow m-auto" src={downArrow} style={{ width: 32 + 'px' }} /></Link>
                ) : (
                        <div className={this.props.landingScrollAction && this.props.landingScrollAction.landingScroll ? 'blue-nav' : ''}>
                            <TabMenu></TabMenu>
                        </div>
                    )}

                {/* tab Menu end */}
            </section>

        )
    }
}

const mapStateToProps = state => {
    //Manp the propps for local state
    return {
        landingScrollAction: state.uiProps.landing_scroll_action,
        header_close_action: state.auth.header_close_action,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        landingScroll: (details) => dispatch(actions.landingScrollEvent(details)),
        goToSection: (details) => dispatch(actions.changeHeaderCloseBtn(details)),
    }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PartnerLanding);