import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Modal, Button, ButtonToolbar } from 'react-bootstrap';
import play from '../../../assets/imgs/icons/play.svg';
import Fade from 'react-reveal/Fade';
import ReactSVG from 'react-svg';
import './landing.scss';
import crossArrow from '../../../assets/imgs/icons/cross.svg';
import * as actions from '../../../store/actions/index';
import { Link, NavLink } from "react-router-dom";
import TabMenu from '../../tab-menu/tab-menu';
import downArrow from '../../../assets/imgs/icons/down-arrow.svg';

class WiserWashLanding extends Component {


    constructor(props) {
        super(props);
        this.state = {
            modalShow: false
        };
        this.keyDown = this.keyDown.bind(this);
        this.touchScroll = this.touchScroll.bind(this);
    }

    setModalShow = (param) => {
        this.setState({ modalShow: param });
    }

    wheel(e) {
        let isCloseBtn = document.getElementById('closeBtn');
        if (e.deltaY > 0) {
            this.props.landingScroll({ 'landingScroll': true });
        } else {
            if (this.props.currentPage === 1 && isCloseBtn) {
                document.getElementById('closeBtn').click();
            }
        }
    }

    keyDown(e) {
        if (e.keyCode == 40) {
            this.props.landingScroll({ 'landingScroll': true });
        }
        if (e.keyCode == 38 && this.props.currentPage === 1 && document.getElementById('closeBtn')) {
            document.getElementById('closeBtn').click();
        }
    }

    touchScroll(e) {
        let isCloseBtn = document.getElementById('closeBtn');
        if (window.scrollY > 20 && !isCloseBtn) {
            this.props.landingScroll({ 'landingScroll': true });
        }

        if (window.scrollY < 10 && this.props.currentPage === 1 && isCloseBtn) {
            document.getElementById('closeBtn').click();
        }
    }


    componentDidMount() {
        window.addEventListener('keydown', this.keyDown);
        window.addEventListener('scroll', this.touchScroll);

        setTimeout(() => {
            document.getElementsByClassName('cmnPmH')[0].parentNode.parentNode.classList.add("parentSec");
            document.getElementsByClassName('cmnPmH')[0].parentNode.parentNode.parentNode.classList.add("stopScroll");
        })
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDown);
        window.removeEventListener('scroll', this.touchScroll);
    }

    render() {
        return (
            <section className={'h-vh landing cmnPmH mob-height position-relative bg-white landing-wash ' + (this.props.landingScrollAction !== undefined && this.props.landingScrollAction.landingScroll ? 'change-bg' : '')} onWheel={(e) => this.wheel(e)}>
                <span className="line wwl-line"></span>
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xl={6} md={5} xs={6} className="ww-gap-lg px-0 pl-md-0 pl-xl-3">
                            <Fade left >
                                <div className="video-holder">
                                    <Link to="/wiser-wash" onClick={() => this.setModalShow(true)} className="d-block position-relative">
                                        <span className="play">
                                            <ReactSVG src={play} style={{ width: 18 + 'px' }} />
                                        </span>
                                        {/* <img alt="" src={tortoiseDenim} className="m-xl-auto object-cover ww-l-l-img mb-4 mb-md-0" /> */}
                                        <img alt="" src={this.props.data.media.images[0]} className="m-xl-auto object-cover ww-l-l-img mb-4 mb-md-0" />
                                    </Link>
                                </div>
                            </Fade>
                        </Col>
                        <Col md={6} xs={10} className="d-flex align-items-center">
                            <Fade right cascade>
                                <figure className="mb-0">
                                    <h1 className="d-block display-1 text-primary mb-3 mb-lg-5">{this.props.data.title}</h1>
                                    <span className="d-block text-primary sw-75">{this.props.data.description}</span>
                                </figure>
                            </Fade>
                        </Col>
                        <Col xs={2} className="d-block d-md-none">
                            <article>
                                <Fade bottom>
                                    {/* <img alt="" src={sea} className="ww-lr-img position-absolute right bottom" /> */}
                                    <img alt="" src={this.props.data.media.images[1]} className="ww-lr-img position-absolute right bottom" />
                                </Fade>
                            </article>
                        </Col>
                    </Row>

                    <article className="d-none d-md-block">
                        <Fade bottom>
                            <img alt="" src={this.props.data.media.images[1]} className="position-absolute right bottom ww-lr-img" />
                        </Fade>
                    </article>
                </Container>


                {/* tab Menu start */}
                {this.props.landingScrollAction && this.props.landingScrollAction.landingScroll && this.props.width > 1024 ? (
                    <Link onClick={() => this.props.goToSection({ 'downArrow': 1 })} to="/wiser-wash"><ReactSVG className="pulse-arrow m-auto" src={downArrow} style={{ width: 32 + 'px' }} /></Link>
                ) : (
                        <div className={this.props.landingScrollAction && this.props.landingScrollAction.landingScroll ? 'blue-nav' : ''}>
                            <TabMenu></TabMenu>
                        </div>
                    )}

                {/* tab Menu end */}

                {/* video modal */}
                <VideoModal
                    videolink={this.props.data.media.videos}
                    show={this.state.modalShow}
                    onHide={() => this.setModalShow(false)}
                />

            </section>

        )
    }
}

function VideoModal(props) {
    return (
        <Modal {...props} size="lg" centered>
            <Button onClick={props.onHide} className="modal-close">
                <ReactSVG src={crossArrow} style={{ width: 12 + 'px' }} />
            </Button>
            <Modal.Body>
                <iframe id="ytplayer" type="text/html" width="100%" height="405"
                    src={props.videolink + '?autoplay=1&disablekb=1'}
                    frameBorder="0" allowFullScreen></iframe>
            </Modal.Body>
        </Modal>
    );
}




const mapStateToProps = state => {
    //Manp the propps for local state
    return {
        landingScrollAction: state.uiProps.landing_scroll_action,
        header_close_action: state.auth.header_close_action,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        landingScroll: (details) => dispatch(actions.landingScrollEvent(details)),
        goToSection: (details) => dispatch(actions.changeHeaderCloseBtn(details)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(WiserWashLanding);