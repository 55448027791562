import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { compose } from 'redux';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { NavLink } from "react-router-dom";
import './partner-type.scss';
import SeaTexture from '../../../assets/imgs/partner/blue-sea-texture-with-waves-and-foam.jpeg';

import PartnerMenu from '../partner-menu/partner-menu';

class PartnerType extends Component {

    constructor(props) {
        super(props);
        const { match: { params } } = this.props;

        this.state = {
            tab: params.tab,
            pageData: null
        };

        if (params.tab === 'brand' || params.tab === 'start-up') {
            // no block
        } else {
            this.props.history.push('/404');
        }
        this.props.history.listen((location, action) => {
            if (this._pageScroller) this._pageScroller.goToPage(1);
        });
    }

    componentDidMount() {

    }

    render() {
        const title = this.props.match.params.tab === 'brand' ? this.props.data.tab_1.content.prtnr_banr_tab1_title : this.props.data.tab_2.content.prtnr_banr_tab2_title;
        const des1 = this.props.match.params.tab === 'brand' ? this.props.data.tab_1.content.prtnr_banr_tab1_description1 : this.props.data.tab_2.content.prtnr_banr_tab2_description1;
        const des2 = this.props.match.params.tab === 'brand' ? this.props.data.tab_1.content.prtnr_banr_tab1_description_2 : this.props.data.tab_2.content.prtnr_banr_tab2_description_2;

        return (
            <section className="h-vh mob-height cmnPmH partner-type py-5 position-relative bg-primary">
                {/* left image start */}
                <article className="d-none d-lg-block">
                    <img alt="" src={SeaTexture} className="object-cover position-absolute left top wpt-l-s-img" />
                </article>
                {/* left image end */}
                <Container className="h-100 flex-column d-flex justify-content-start description pt-lg-5">
                    <div className="d-lg-none">
                        <PartnerMenu data={this.props.data}></PartnerMenu>
                    </div>

                    <Row className="mb-4 pt-lg-3">
                        <Col md={8} className="d-flex align-items-center">
                            <Fade bottom cascade>
                                <figure className="mb-0">
                                    <h2 className="d-block display-1 title text-white pr-md-5">{title}</h2>
                                </figure>
                            </Fade>
                        </Col>
                    </Row>
                    <Row className="mb-lg-5">
                        <Col md={{ span: 4 }}>
                            <Fade bottom>
                                <p className="text-white mb-0">{des1}</p>
                            </Fade>
                        </Col>
                        <Col md={{ span: 4, offset: 4 }}>
                            <Fade bottom>
                                <p className="firstReavel text-white mb-0">{des2}</p>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default compose(withRouter)(PartnerType);