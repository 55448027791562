import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class WiserWashAim extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cauntA: true
        }
    }
    onChangeA = (e) => {
        if (e && this.state.cauntA) {
            this.myCountUpA.restart();
            this.setState({ cauntA: false });
        }
    }

    createMarkup(data) {
        return { __html: data };
    }

    render() {
        return (
            <section className="bg-primary aim mob-height cmnPmH h-vh position-relative text-white d-flex align-items-center">
                <Container>
                    <Row>
                        <Col md={{ span: 6, offset: 6 }} xs={{ span: 7, offset: 5 }} className="d-flex justify-content-end">
                            <Fade bottom>
                                <h4 className="d-block display-4 content-rw-100 text-white mb-0">{this.props.data.title}</h4>
                            </Fade>
                        </Col>
                        <Col md={12}>
                            <span className="d-block display-lg text-center my-md-5 my-3">
                                <VisibilitySensor onChange={this.onChangeA}>
                                    <CountUp
                                        start={0}
                                        end={parseFloat(this.props.data.counter)}
                                        duration={5}
                                        separator=" "
                                        decimals={3}
                                        decimal="."
                                        suffix=""
                                        ref={countUp => { this.myCountUpA = countUp; }}
                                    >
                                    </CountUp>
                                </VisibilitySensor>
                            </span>
                        </Col>
                        <Col md={{ span: 6, offset: 6 }} xs={{ span: 7, offset: 5 }} className="d-flex justify-content-end">
                            <Fade bottom>
                                <figure className="content-rw-100 mb-0">
                                    <span className="d-block display-4" dangerouslySetInnerHTML={this.createMarkup(this.props.data.description)}></span>
                                </figure>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default WiserWashAim;